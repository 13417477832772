@import "../../../styles/functions.scss";

.container {
  @include flex(column, flex-start, flex-start, 100%);
  gap: 32px;
  padding: 50px 60px 0px 60px;
  background-color: #efefef;
  .containerHeader {
    .containerTitle {
      gap: 8px;
      @include flex(row, center, center);
      img {
        cursor: pointer;
        margin-top: 2px;
        width: 28px;
      }
      .title {
        @include font("Poppins-ExtraBold", 800, 40px, #497078);
      }
    }
    .boxHeader {
      @include flex(column, center, center);
      @include font("Poppins-Medium", 500, 20px, #6eaebb);
      border: #9ec5ce 2px solid;
      margin-top: 15px;
      width: 120px;
      height: 42px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    }
  }
  .containerBoxQuestions {
    @include flex(column, center, center, 100%);
    .boxQuestions {
      @include flex(column, center, center, 70%);
      gap: 30px;
      min-width: 750px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      border: #9ec5ce 2px solid;
      height: 242px;
      .fontQuestions {
        @include font("Poppins-Medium", 500, 15px, #373737);
        width: 80%;
        text-align: center;
        height: 60px;
      }
      .containerButtom {
        @include flex(row, center, center);
        gap: 70px;
        width: 100%;
        width: 100%;

        .buttomQuestion {
          cursor: pointer;
          @include flex(row, center, center);
          @include font("Poppins-Medium", 500, 20px, #6eaebb);
          border: #9ec5ce 2px solid;
          padding: 20px;
          border-radius: 15px;
          box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
            0 4px 8px 3px rgba(60, 64, 67, 0.15);
        }
        .buttomQuestion:hover {
          box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
            0 6px 10px 4px rgba(60, 64, 67, 0.15);
        }
      }
    }
  }
  .containerBack {
    cursor: pointer;
    @include font("Poppins-Medium", 500, 20px, #818181);
    font-style: italic;
    opacity: 0.6;
  }
  .containerBack:hover {
    transform: scale(1.03);
  }
}
