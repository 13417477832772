@import "../../../styles/functions.scss";
.container {
  height: 72px;
  @include flex(row, center, space-between, 100%);
  background-color: #6eaebb;
  .logo {
    margin-left: 10px;
    cursor: pointer;
  }
  .botonLogin {
    @include font("Poppins-SemilBold", 600, 25, #497078);
    cursor: pointer;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.4)
    );
    border: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.4)
        )
        solid,
      2px;
    padding: 6px 20px;
    border-radius: 15px;
    margin-right: 16px;
  }
  .botonLogin:hover {
    transform: scale(1.02);
    background-color: #61a9b8;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
  }

  .containerImgProfile {
    // position: relative;
    cursor: pointer;
    .imgProfile {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
}
