@import "../../styles/functions.scss";
.container {
  @include flex(column, flex-start, flex-start, 100%);
  background-color: #efefef;
  padding: 50px 0px;
  gap: 48px;
  .containerHeader {
    width: 100%;
    padding: 0px 50px;
  }
  .containerContent {
    @include flex(row, center, center, 100%);
    gap: 24px;
    flex-wrap: wrap;
    .boxSkills {
      @include flex(column, center, space-between, 275px);
      height: 224px;
      padding: 16px;
      border: #9ec5ce 3px solid;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      .title {
        text-align: center;
        @include font("Poppins-SemiBold", 600, 20px, #497078);
      }

      .description {
        @include font("Poppins-Medium", 600, 15px, #373737);
        text-align: center;
        font-style: italic;
        width: 100%;
      }
      .button {
        cursor: pointer;
        @include flex(row, center, center, 186px);
        @include font("Poppins-Medium", 600, 15px, #373737);
        background-color: rgba(110, 174, 187, 1);
        padding: 8px 24px;
        height: 42px;
        border-radius: 15px;
      }
      .button:hover {
        transform: scale(1.01);
      }
    }
  }
  .containerButton {
    @include flex(row, center, center, 100%);
    .buttoNext,
    .buttonNotNext {
      cursor: pointer;
      @include flex(row, center, center, 244px);
      @include font("Poppins-Regular", 400, 20px, #efefef);
      background-image: linear-gradient(to right, #6eaebb, #497078);
      height: 44px;
      border-radius: 15px;
      background-color: #6eaebb;
    }
    .buttoNext:hover {
      transform: scale(1.02);
      box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
        0 6px 10px 4px rgba(60, 64, 67, 0.15);
    }
    .buttonNotNext {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
