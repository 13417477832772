@import "../../styles/functions.scss";
.container {
  background-color: #efefef;

  .deleteMobile {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
