@import "../../styles/functions.scss";
.container {
  @include flex(column, center, flex-start, 100%);
  background-color: #efefef;
  padding: 50px 50px 0px 50px;
  gap: 40px;
  .containerContent {
    @include flex(column, flex-start, flex-start, 75%);
    gap: 24px;

    .content {
      @include flex(column, center, flex-start);
      padding: 40px 8px 10px 8px;
      // gap: 24px;
      border: #9ec5ce 2px solid;
      border-radius: 15px;
      .title {
        @include font("Poppins-SemiBold", 600, 33px, #497078);
      }
      .description {
        @include flex(column, center, center, 100%);
        text-align: center;
        @include font("Poppins-Medium", 600, 18px, #373737);
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 24px;
        h1 {
          @include font("Poppins-SemiBold", 600, 33px, #497078);
        }

        li {
          padding: 4px;
        }
      }
    }
    .containerButton {
      @include flex(row, center, center, 100%);

      .buttoNext {
        cursor: pointer;
        @include flex(row, center, center, 220px);
        @include font("Poppins-SemiBold", 600, 20px, #efefef);
        background-image: linear-gradient(to right, #6eaebb, #497078);
        height: 47px;
        border-radius: 15px;
        margin: 24px 0px 40px 0px;
        text-decoration: none;
        background-color: linear-gradient(
          122deg,
          rgba(255, 255, 255, 0.18) 17.03%,
          rgba(255, 255, 255, 0.05) 81.81%
        );
      }
      .buttoNext:hover {
        transform: scale(1.01);
        box-shadow: 0 3px 4px 0 rgba(60, 64, 67, 0.3),
          0 4px 8px 3px rgba(60, 64, 67, 0.15);
      }
    }
  }
}
