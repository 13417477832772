@import "../../../../styles/functions.scss";

.containerStep {
  @include flex(column, flex-start, space-between);
  gap: 24px;
  .step {
    @include flex(row, center, center, 100%);
    gap: 8px;
    .circleSelect,
    .circleNormal {
      @include flex(column, center, center);
      @include font("Poppins-SemiBold", 700, 18px, #497078);
      width: 40px;
      height: 40px;
      border-radius: 25px;
      box-sizing: border-box;
      background-color: #9ec5ce;
    }

    .circleNormal {
      color: #9ec5ce;
      box-sizing: border-box;
      border: 2px solid #9ec5ce;
      background-color: #efefef;
    }
    .textNormal {
      @include font("Poppins-Regular", 700, 15px, #497078);
      width: 112px;
    }
  }
}

.container {
  @include flex(column, center, center);
  background-color: #efefef;
  border-radius: 15px;
  border: #9ec5ce 2px solid;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  padding: 24px 36px 8px 36px;

  .containerStep {
    @include flex(row, flex-start, space-between);
    gap: 30px;
    .step {
      @include flex(column, center, center, 100%);
      gap: 8px;
      .circleSelect,
      .circleNormal {
        @include flex(column, center, center);
        @include font("Poppins-SemiBold", 700, 18px, #497078);
        width: 40px;
        height: 40px;
        border-radius: 25px;
        box-sizing: border-box;
        background-color: #9ec5ce;
      }

      .circleNormal {
        color: #9ec5ce;
        box-sizing: border-box;
        border: 2px solid #9ec5ce;
        background-color: #efefef;
      }
      .textNormal {
        @include font("Poppins-Regular", 700, 18px, #497078);
        text-align: center;
      }
    }
  }
}
