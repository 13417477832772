@import "../../styles/functions.scss";

.container {
  @include flex(column, flex-start, flex-start);
  padding: 40px;

  .rowClient {
    @include flex(row, flex-start, flex-start);
    gap: 20px;
    padding: 20px;
  }
}
