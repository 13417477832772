@import "../../../styles/functions.scss";
.container {
  @include flex(row, center, flex-start, 100%);
  padding: 0 60px;
  gap: 100px;
  height: 100dvh;
  .info {
    @include flex(column, flex-start, center);
    flex: 1;
    gap: 36px;
    @media (max-width: 768px) {
      @include flex(column, center, space-around, 100%);
      gap: 16px;
    }

    .logoHome {
      width: 0%;
    }
    .textContainer {
      @include flex(column, center, center);
      margin-left: 15px;
      gap: 30px;
      @media (max-width: 768px) {
        margin-left: 0px;
        gap: px;
      }
      .title {
        @include font("Poppins-SemiBold", 600, 30px, #497078);
        line-height: 45px;
        margin-bottom: 30px;
        @media (max-width: 768px) {
          @include font("Poppins-SemiBold", 600, 20px, #497078);
          margin-bottom: 0px;
          text-align: center;
        }
      }
      .subTitle {
        @include font("Poppins-Medium", 500, 20px, #373737);
        line-height: 30px;
        width: 390px;
        @media (max-width: 768px) {
          @include font("Poppins-Medium", 500, 15px, #373737);
          width: 270px;
          text-align: center;
          line-height: 100%;
        }
      }
      .imageHomeMobile {
        @media (min-width: 768px) {
          display: none;
        }
        margin-top: 16px;
        width: 223px;
        height: 170px;
        border-radius: 57px;
      }
    }
  }
  .imageContainer {
    flex: 1;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    .imageHome {
      width: 700px;
      max-width: 90%;
      border-radius: 57px;
    }
    .textImage {
      position: absolute;
      @include font("Poppins-SemiBold", 600, 17px, #497078);
      width: 400px;
      font-style: italic;
      border: #9ec5ce 2px solid;
      border-radius: 15px;
      padding: 24px;
      box-sizing: border-box;
      line-height: 110%;
      background-color: rgba(255, 255, 255, 0.6);
      bottom: -65px;
      right: 0px;
    }
  }
}
