@import "../../styles/functions.scss";

.container {
  @include flex(column, flex-start, flex-start, 100%);
  padding: 50px 60px 0px 60px;

  .description {
    @include font("Poppins-Medium", 500, 15px, #373737);
    font-style: italic;
    width: 75%;
    padding: 32px;
  }
  .containerContent {
    @include flex(column, center, center, 100%);
    .box {
      @include flex(column, center, center, 641px);
      flex: 1;
      height: 100%;
      gap: 24px;
      padding: 24px 32px;
      border-radius: 15px;
      border: #9ec5ce 3px solid;
      border-radius: 15px;
      box-shadow: 0 3px 3px 0 rgba(60, 64, 67, 0.3),
        0 2px 4px 3px rgba(60, 64, 67, 0.15);
      img {
        width: 64px;
      }
      .textBox {
        @include font("Poppins-Medium", 500, 15px, #818181);
        font-style: italic;
      }
      .buttomCandely {
        cursor: pointer;
        @include flex(row, center, center, 230px);
        @include font("Poppins-Medium", 500, 15px, #efefef);
        background-image: linear-gradient(to right, #6eaebb, #497078);
        height: 47px;
        border: none;
        border-radius: 11px;
        background-color: linear-gradient(
          122deg,
          rgba(255, 255, 255, 0.18) 17.03%,
          rgba(255, 255, 255, 0.05) 81.81%
        );
      }
      .buttomCandely:hover {
        transform: scale(1.01);
        box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
          0 2px 4px 1px rgba(60, 64, 67, 0.15);
      }
    }
  }
}
