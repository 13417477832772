@import "../../../../styles/functions.scss";
.container {
  @include flex(column, center, center, 100%);
  background-color: #efefef;
  padding: 50px 50px 0px 50px;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 16px 10px 0px 10px;
    gap: 24px;
   }
  .containerContent {
    @include flex(row, center, center, 95%);
    gap: 40px;
    .contentText {
      @include flex(column, flex-start, flex-start, 40%);
      @media (max-width: 768px) {
        display: none;
      }
      .text {
        @include font("Poppins-Medium", 500, 15px, #373737);
        font-style: italic;
        width: 400px;
      }
    }
    .contentForm {
      @include flex(column, flex-start, flex-start, 100%);
      flex: 1;
      height: 100%;
      gap: 8px;
      padding: 24px 32px;
      border-radius: 15px;
      border: #9ec5ce 3px solid;
      border-radius: 15px;
      box-sizing: border-box;
      bottom: 40px;
      position: relative;
      box-shadow: 0 3px 3px 0 rgba(60, 64, 67, 0.3),
        0 2px 4px 3px rgba(60, 64, 67, 0.15);
        @media (max-width: 768px) {
          bottom: 0px;
          gap: 12px;
         }
      
      .containerCity {
        @include flex(row, flex-start, flex-start, 100%);
        gap: 30px;
      }
      .inputContainer {
        @include flex(column, flex-start, flex-start, 100%);
        gap: 8px;
        .labelText {
          @include font("Poppins-Medium", 500, 20px, #497078);
          font-style: italic;
          margin-left: 16px;
        }
        .inputLarge {
          @include font("Poppins-SemiBold", 500, 14px, #333333);
          border-radius: 15px;
          font-style: italic;
          width: 100%;
          height: 40px;
          padding: 0px 16px;
          border: none;
          background-color: #fff;
          outline: 2px solid #9ec5ce;
          box-shadow: 0 3px 3px 0 rgba(60, 64, 67, 0.3),
            0 2px 4px 3px rgba(60, 64, 67, 0.15);
        }
        input::placeholder {
          @include font("Poppins-SemiBold", 500, 15px, #818181);
          font-style: italic;
        }
        .selectProject {
          cursor: pointer;
          border-radius: 15px;
          width: 100%;
          height: 40px;
          padding: 6px 16px;
          background-color: #fff;
          border: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-image: url("../../../../assets/pages/premium/arrowDown.png");
          background-repeat: no-repeat;
          background-position: 95% center;
          outline: 2px solid #9ec5ce;
          @include font("Poppins-SemiBold", 500, 15px, #333333);
          font-style: italic;
          text-transform: uppercase;
        }
      }

      .containerButton {
        @include flex(row, center, center, 100%);

        .buttonFooter,
        .buttonFooterBloqued {
          cursor: pointer;
          @include flex(row, center, center, 120px);
          @include font("Poppins-Regular", 500, 15px, #efefef);
          background-image: linear-gradient(to right, #6eaebb, #497078);
          height: 48px;
          box-sizing: border-box;
          border-radius: 15px;
          background-color: linear-gradient(
            122deg,
            rgba(255, 255, 255, 0.18) 17.03%,
            rgba(255, 255, 255, 0.05) 81.81%
          );
        }
        .buttonFooter:hover {
          transform: scale(1.01);
          box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
            0 2px 4px 1px rgba(60, 64, 67, 0.15);
        }
        .buttonFooterBloqued {
          cursor: not-allowed;
        }
      }
    }
  }
}
