@import "../../../styles/functions.scss";

.container {
  @include flex(column, center, center, 100%);
  gap: 24px;
  height: calc(100vh - 72px);
  .imageProfile {
    width: 132px;
    border-radius: 50%;
  }
  .containterTitle {
    @include flex(column, center, center, 100%);
    gap: 8px;
    .title {
      text-transform: uppercase;
      @include font("Poppins-ExtraBold", 800, 40px, #497078);
      @media (max-width:768px) {
        font-size: 30px;
        text-align: center;
      }
    }
    .subTitle {
      @include font("Poppins-SemiBold", 600, 30px, #497078);
      @media (max-width:768px) {
        font-size: 20px;
        text-align: center;
        width: 240px;
      }
    }
  }
  .description {
    @include font("Poppins-Medium", 600, 16px, #373737);
    // font-style: italic;
    width: 793px;
    text-align: center;
    padding: 32px;
    @media (max-width:768px) {
      max-width: 90%;
    }
  }
  .buttomHome {
    cursor: pointer;
    @include flex(row, center, center, 234px);
    @include font("Poppins-Regular", 400, 20px, #efefef);
    background-image: linear-gradient(to right, #6eaebb, #497078);
    height: 47px;
    border-radius: 15px;
    background-color: linear-gradient(
      122deg,
      rgba(255, 255, 255, 0.18) 17.03%,
      rgba(255, 255, 255, 0.05) 81.81%
    );
  }
  .buttomHome:hover {
    transform: scale(1.01);
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
      0 2px 4px 1px rgba(60, 64, 67, 0.15);
  }
}
