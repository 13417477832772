@import "../../../styles/functions.scss";

.container {
  @include flex(row, center, flex-start, 100%);
  padding: 0px 60px;
  min-height: calc(100dvh - 72px);
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }
  .info {
    @include flex(column, flex-start, center);
    flex: 1;
    gap: 36px;
    @media (max-width: 768px) {
      align-items: center;
      width: 90%;
    }
    .logoHome {
      width: 50%;
    }
    .textContainer {
      @include flex(column, flex-start, center);
      margin-left: 15px;
      gap: 20px;
      @media (max-width: 768px) {
        margin-left: 0px;
      }
      .title {
        @include font("Poppins-SemiBold", 600, 30px, #497078);
        width: 390px;
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
        }
      }
      .subTitle {
        @include font("Poppins-Medium", 500, 20px, #373737);
        line-height: 30px;
        width: 390px;
        @media (max-width: 768px) {
          text-align: center;
          width: 100%;
        }
      }
    }
    .botonStart {
      cursor: pointer;
      @include flex(column, center, center);
      @include font("Poppins-SemiBold", 600, 20px, #497078);
      border: #9ec5ce 2px solid;
      width: 168px;
      height: 45px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    }
    .botonStart:hover {
      box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
        0 6px 10px 4px rgba(60, 64, 67, 0.15);
    }
  }
  .imageContainer {
    flex: 1;
    position: relative;
    @media (max-width: 768px) {
      display: none;
    }
    .imageHome {
      width: 90%;
      border-radius: 57px;
    }
    .textImage {
      @media (max-width: 768px) {
        display: none;
      }
      position: absolute;
      @include font("Poppins-SemiBold", 600, 16px, #497078);
      font-style: italic;
      width: 407px;
      border: #9ec5ce 2px solid;
      border-radius: 15px;
      padding: 24px;
      box-sizing: border-box;
      line-height: 140%;
      background-color: rgba(255, 255, 255, 0.6);
      bottom: -80px;
      right: 0px;
    }
  }
}
