@import "../../../../../styles/functions.scss";
.containerAI {
  @include flex(row, center, flex-start, 100%);
  gap: 16px;
  @media (max-width: 7680px) {
    align-items: 
      flex-start;
  }
  .circleImg {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .containerTextAi {
    @include flex(row, center, space-between);
    @include font("Poppins-SemiBold", 600, 16px, #4c4f63);
    max-width: 60%;
    padding: 12px;
    gap: 20px;
    border-radius: 8px;
    background: #afcfd6;
    @media (max-width: 1000px) {
      max-width: 70%;
    }
    @media (max-width: 7680px) {
      max-width: 90%;
    }
  }
}
