@import "../../../styles/functions.scss";

.container {
  @include flex(column, center, flex-start, 100%);
  height: calc(100vh - 72px);
  padding: 50px 60px 0px 60px;
  background-color: #efefef;
  .content {
    @include flex(row, center, center);
    gap: 60px;
    margin-top: 30px;
    .boxSkills {
      @include flex(column, center, flex-start, 40%);
      padding: 50px;
      gap: 24px;
      flex: 1;
      height: 100%;
      border: #9ec5ce 4px solid;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      .title {
        text-align: center;
        @include font("Poppins-SemiBold", 600, 30px, #497078);
      }
      .subtitle {
        @include font("Poppins-Medium", 600, 20px, #6eaebb);
        text-align: center;
        font-style: italic;
      }
    }
  }
}
