@import "../../../styles/functions.scss";
.container {
  @include flex(column, center, center, 100%);
  gap: 40px;
  padding: 0px 60px;
  height: 100vh;
  .title {
    @include font("Poppins-SemiBold", 600, 30px, #497078);
  }
  span {
    @include font("Poppins-Medium", 500, 20px, #373737);
    text-align: center;
    width: 90%;
  }

  .botonTest {
    @include flex(column, center, center);
    @include font("Poppins-SemiBold", 600, 20px, #497078);
    border: #9ec5ce 2px solid;
    width: 201px;
    height: 45px;
    border-radius: 15px;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
  }
}

.containerMobile {
  @include flex(column, center, space-around, 100%);
  height: 100dvh;
  .title {
    @include font("Poppins-SemiBold", 600, 30px, #497078);
    text-align: center;
  }
  .description {
    @include font("Poppins-Medium", 500, 15px, #373737);
    text-align: center;
    width: 260px;
  }

  .botonTest {
    @include flex(column, center, center);
    @include font("Poppins-SemiBold", 600, 20px, #497078);
    border: #9ec5ce 2px solid;
    width: 201px;
    height: 45px;
    border-radius: 15px;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
  }
}
