@import "../../../styles/functions.scss";
.container {
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    border-top: #6eaebb 1.5px solid;
  }
  .content {
    @include flex(row, center, space-between, 100%);
    padding: 12px 16px 0px 16px;
    @media (max-width: 768px) {
      @include flex(row, flex-end, space-between, 100%);
      padding: 12px 8px 8px 8px;
    }
    .logoContainer {
      @include flex(column, flex-start, flex-start);
      gap: 16px;
      @media (max-width: 768px) {
        @include flex(column, flex-start, flex-start);
        gap: 8px;
      }
      img {
        width: 60px;
        @media (max-width: 768px) {
          width: 94px;
          height: 35px;
        }
      }
      .textTerms {
        @include font("Poppins-SemiBold", 500, 18px, #333);
        @media (max-width: 768px) {
          @include font("Poppins-Medium", 500, 12px, rgba(55, 55, 55, 0.5));
        }
      }
    }

    .contactContainer {
      @include flex(row, center, space-between);
      padding: 0px 20px;
      gap: 80px;
      .infoContainer {
        @include flex(column, center, center);
        gap: 8px;
        @media (max-width: 768px) {
          display: none;
        }
        .textContact {
          @include font("Poppins-SemiBold", 700, 18px, #497078);
        }
        .textEmail {
          @include font("Poppins-SemiBold", 600, 16px, #373737);
        }
      }

      .botonBlog {
        cursor: pointer;
        @include flex(column, center, center);
        @include font("Poppins-SemiBold", 600, 25px, #497078);
        border: #9ec5ce 2px solid;
        width: 181px;
        height: 72px;
        border-radius: 15px;
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
          0 4px 8px 3px rgba(60, 64, 67, 0.15);
      }
      .botonStart:hover {
        box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
          0 6px 10px 4px rgba(60, 64, 67, 0.15);
      }
      .containerLogo {
        @include flex(column, center, center);
        gap: 8px;
        @media (max-width: 768px) {
          @include flex(row, center, center);
        }
        img {
          width: 30px;
          cursor: pointer;
        }
        img:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
