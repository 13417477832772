@import "../../styles/functions.scss";
.container {
  @include flex(column, center, flex-start, 100%);
  background-color: #efefef;
  min-height: calc(100dvh - 72px);
  padding: 50px 60px 0px 60px;
  gap: 16px;

  .containerContent {
    @include flex(row, flex-start, flex-start, 100%);

    .firstColumn {
      padding: 0px 40px 0px 0px;
      border-right: 2px solid #9ec5ce;
      gap: 16px;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        display: none;
      }
      .boxAccount {
        @include flex(column, flex-start, flex-start);
        border: 1px solid #497078;
        border-radius: 10px;
        padding: 16px;
        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
          0 4px 8px 3px rgba(60, 64, 67, 0.15);
        .titleAcount {
          @include font("Poppins-SemiBold", 600, 25px, #497078);
        }
        .school {
          @include font("Poppins-Medium", 500, 20px, #373737);
          font-style: italic;
        }
        .plan {
          @include font("Poppins-Medium", 500, 20px, #818181);
          font-style: italic;
        }
      }
      .containerBlog {
        @include flex(column, none, none);
        padding: 0px 0px 16px 24px;
        gap: 16px;
        .blog {
          cursor: pointer;
          @include font("Poppins-SemiBold", 600, 20px, #497078);
        }
        .blog:hover {
          transform: scale(1.02);
        }
      }
      .boxTerms {
        cursor: pointer;
        @include flex(row, center, space-between);
        @include font("Poppins-SemiBold", 600, 15px, #497078);

        box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
          0 4px 8px 3px rgba(60, 64, 67, 0.15);
        gap: 16px;
        border: solid 1px #497078;
        padding: 12px 16px;
        border-radius: 10px;
        img {
          width: 23px;
        }
      }
      .boxTerms:hover {
        transform: scale(1.02);
        box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
          0 6px 10px 4px rgba(60, 64, 67, 0.15);
      }
    }
    .secondColumn {
      flex: 1;
      padding: 0px 0px 0px 40px;
      border-left: 2px solid #9ec5ce;
      @media (max-width: 768px) {
        padding: 0px ;
        border-left: none;
      }
      .containerProgres {
        @include flex(column, center, center, 100%);
        gap: 32px;
        .buttoNext {
          cursor: pointer;
          @include flex(row, center, center, 220px);
          @include font("Poppins-SemiBold", 600, 20px, #efefef);
          background-image: linear-gradient(to right, #6eaebb, #497078);
          height: 47px;
          border-radius: 15px;
          background-color: linear-gradient(
            122deg,
            rgba(255, 255, 255, 0.18) 17.03%,
            rgba(255, 255, 255, 0.05) 81.81%
          );
        }
        .buttoNext:hover {
          transform: scale(1.01);
          box-shadow: 0 3px 4px 0 rgba(60, 64, 67, 0.3),
            0 4px 8px 3px rgba(60, 64, 67, 0.15);
        }
      }
    }
  }
}
