@import "../../../styles/functions.scss";

.container {
  @include flex(column, center, flex-start, 100%);
  min-height: calc(100vh - 72px);
  padding: 50px 60px 0px 60px;
  .content {
    @include flex(row, center, center);
    gap: 60px;
    margin-top: 30px;
    .boxSkills {
      @include flex(column, center, flex-start);
      padding: 50px;
      gap: 24px;
      border: #9ec5ce 4px solid;
      width: 589px;
      height: 290px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      .title {
        @include font("Poppins-SemiBold", 600, 30px, #497078);
      }
      .subtitle {
        @include font("Poppins-Medium", 600, 20px, #6eaebb);
        text-align: center;
        font-style: italic;
      }
      .description {
        @include font("Poppins-Medium", 600, 15px, #373737);
        text-align: center;
        text-overflow: ellipsis;
        font-style: italic;
      }
    }
  }

  .buttonContainer {
    padding: 24px 24px 0px 24px;
  }
}
