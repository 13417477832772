@import "../../../styles/functions.scss";

.containerProfile {
  @include flex(row, flex-start, flex-start, 100%);
  gap: 22px;

  .imageProfile {
    width: 98px;
    border-radius: 50%;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .profileText {
    @include flex(column, flex-start, flex-start);

    .containerTitle {
      gap: 8px;
      @include flex(row, center, center);
      @media (max-width: 768px) {
        gap: 8px;
      }
      img {
        cursor: pointer;
        margin-top: 2px;
        width: 28px;
        @media (max-width: 768px) {
          width: 20px;
        }
      }
      .title {
        @include font("Poppins-ExtraBold", 700, 40px, #497078);
        @media (max-width: 768px) {
         font-size: 30px;
        }
      }
    }
    .name {
      @include flex(row, center, center);
      @include font("Poppins-SemiBold", 600, 30px, #497078);
      @media (max-width: 768px) {
        font-size: 20px;
        margin-left: 4px;
       }
      text-transform: uppercase;
      img {
        margin-left: 8px;
        width: 45px;
        height: 45px;
      }
    }
  }
}
