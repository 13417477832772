@import "../../../../styles/functions.scss";
.container {
  @include flex(column, flex-start, space-between, 100%);
  position: relative;

  gap: 24px;
  border-radius: 20px;
  padding: 30px 30px 0px 30px;
  flex: 1;
  border: #9ec5ce 2px solid;
  overflow: auto;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    padding: 16px 16px 0px 16px;
  }
  .containerMessages {
    @include flex(column, flex-start, space-between, 100%);
    gap: 12px;
  }

  .containerLoading {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 8px;
    .loader {
      width: 30px;
      aspect-ratio: 2;
      --_g: no-repeat
        radial-gradient(circle closest-side, #222121 90%, #14141400);
      background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
      background-size: calc(100% / 3) 50%;
      animation: l3 1s infinite linear;
    }
    @keyframes l3 {
      20% {
        background-position: 0% 0%, 50% 50%, 100% 50%;
      }
      40% {
        background-position: 0% 100%, 50% 0%, 100% 50%;
      }
      60% {
        background-position: 0% 50%, 50% 100%, 100% 0%;
      }
      80% {
        background-position: 0% 50%, 50% 50%, 100% 100%;
      }
    }
  }
}
