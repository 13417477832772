@import "../../../../styles/functions.scss";

.mask {
  position: absolute;
  width: 100vw;
  height: 100vh;
  bottom: 0px;
  right: 0;
  cursor: auto;
}
.container {
  @include flex(column, center, flex-start, 225px);
  position: absolute;
  right: 36px;
  top: 48px;
  height: 310px;
  border-radius: 15px;
  background-color: #efefef;
  border: #9ec5ce 2px solid;
  z-index: 9999;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  @media (max-width: 768px) {
    height: 190px;
  }
  .containerCross {
    @include flex(row, center, flex-end, 100%);
    padding: 16px 16px 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
    img {
      width: 12px;
    }
  }
  .imgProfile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .greeting {
    @include font("Poppins-SemiBold", 600, 21px, #497078);
    margin-bottom: 24px;
    @media (max-width: 768px) {
      margin-bottom: 0px;
    }
  }
  .buttonMenu {
    @media (max-width: 768px) {
      display: none;
    }
    cursor: pointer;
    @include flex(row, center, center, 130px);
    @include font("Poppins-SemiBold", 700, 18px, #497078);
    height: 36px;
    border-radius: 8px;
    background-color: #f5f4f4;
    border: #9ec5ce 2px solid;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);
    margin: 6px 0px;
  }
  .buttonMenu:hover,
  .closeSession:hover {
    transform: scale(1.01);
  }
  .closeSession {
    cursor: pointer;
    margin-top: 20px;
    @include font("Poppins-SemiBold", 600, 16px, #343f56);
  }
}
