@import "../../../styles/functions.scss";

.container {
  padding: 50px;
  background-color: #efefef;
  .content {
    @include flex(column, center, center);
    gap: 20px;
    margin-top: 30px;
    .boxSkills {
      @include flex(row, center, flex-start);
      gap: 24px;
      border: #9ec5ce 4px solid;
      width: 1024px;
      height: 134px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
      .containerTitle {
        flex: 1;
        height: 100%;
        gap: 8px;
        padding: 20px;
        @include flex(column, flex-start, center);
        .title {
          @include font("Poppins-SemiBold", 600, 20px, #497078);
          text-transform: uppercase;
        }
        .description {
          @include font("Poppins-Medium", 500, 15px, #373737);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .porcent {
        @include font("Poppins-Medium", 500, 50px, #6eaebb);
        padding: 20px;
        text-align: center;
      }
    }
  }
}
