@import "../../styles/functions.scss";
.containerMobile {
  @include flex(column, center, center, 100%);
  padding: 16px;
  .boxAccount {
    @include flex(column, center, center, 92%);
    border: 2px solid #87c0cc;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
      0 4px 8px 3px rgba(60, 64, 67, 0.15);

    .logoHome {
      width: 124px;
      height: 70px;
    }
    .containerCross {
      @include flex(row, center, flex-end, 100%);
      padding: 0px;
      box-sizing: border-box;
      cursor: pointer;
      img {
        width: 12px;
      }
    }
    .description {
      @include font("Poppins-Medium", 600, 15px, #373737);
      text-align: center;
      width: 250px;
      margin: 24px 0px;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
}
