@import "../../../../../styles/functions.scss";
.container {
  @include flex(column, flex-end, flex-end, 100%);
  gap: 8px;
  .containerAlumn {
    @include flex(row, center, flex-end, 100%);
    gap: 16px;

    .containerTextAi {
      @include flex(row, center, space-between);
      @include font("Poppins-SemiBold", 600, 16px, #4c4f63);
      max-width: 60%;
      padding: 12px;
      gap: 20px;
      border-radius: 8px;
      background: #cadde1;
      @media (max-width: 1000px) {
        max-width: 70%;
      }
    }
    .circleImg {
      width: 50px;
      height: 50x;
      border-radius: 50%;
    }
  }
  .containerError {
    @include flex(row, center, flex-end);
    box-sizing: border-box;
    max-width: 60%;
    @include font("Poppins-SemiBold", 400, 14px, #333);
  }
}
