@import "../../../styles/functions.scss";

.container {
  @include flex(column, center, center, 100%);
  min-height: calc(100vh - 72px);
  gap: 30px;

  .borderContainer {
    @include flex(row, flex-start, flex-start, 80%);
    border-radius: 20px;
    border: #9ec5ce 3px solid;
    padding: 24px;
    gap: 16px;
    border-radius: 20px;
    @media (max-width: 768px) {
      padding: 0px;
      border: none;
      border-radius: 0px;
      padding: 0px;
      width: 90%;
    }
    .containerImg {
      @include flex(column, flex-start, flex-start);
      height: 70vh;
      gap: 16px;
      @media (max-width: 1000px) {
        display: none;
        height: 80vh;
      }

      .profileText {
        @include flex(column, flex-start, flex-start);
        margin-left: 8px;
        .title {
          @include font("Poppins-ExtraBold", 700, 40px, #497078);
        }
        .name {
          @include font("Poppins-SemiBold", 600, 30px, #497078);
          text-transform: uppercase;
        }
      }

      .imgStyles {
        overflow: hidden;
        height: 90%;
        width: 100%;
        flex-grow: 1;
      }
    }

    .containerChatBox {
      @include flex(column, flex-start, flex-start, 100%);
      flex: 1; /* La columna del chat ocupa el espacio restante */
      height: 70vh; /* Altura del contenedor principal (100% del alto de la pantalla) */
      overflow: auto;
      gap: 16px;
      @media (max-width: 1000px) {
        padding: 16px;
      }
      @media (max-width:768px) {
        padding: 0px;
      }
    }
  }
  .containerBack {
    width: 80%;
    .buttonBack {
      cursor: pointer;
      @include font("Poppins-Medium", 600, 18px, rgba(55, 55, 55, 1));
    }
  }
}
