@import "../../../styles/functions.scss";

.container {
  @include flex(column, center, center, 100%);
  position: relative;
  box-sizing: border-box;
  min-height: 100dvh;
  background-image: url("../../../assets/Logos/keyboardBackground.png");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: rgba(0, 0, 0, 1);
  gap: 40px;
  padding: 50px;
  @media (max-width: 768px) {
    display: none;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      52,
      63,
      86,
      0.5
    ); /* Color de superposición con transparencia */
    z-index: 1; /* Asegura que el color esté siempre encima de la imagen */
  }
  .title {
    @include font("Poppins-SemiBold", 600, 30px, #efefef);
    z-index: 2;
  }
  .description {
    @include font("Poppins-Medium", 500, 19px, #efefef);
    z-index: 2;
    text-align: center;
    width: 70%;
  }
  .cardContainer {
    @include flex(row, center, center, 100%);
    z-index: 2;
    box-sizing: border-box;
    .card {
      @include flex(column, flex-start, flex-start, 520px);
      position: relative;
      border-radius: 15px;
      padding: 12px 10px 48px 10px;
      gap: 8px;

      height: 100%;
      border: #9ec5ce 2px solid;
      border-radius: 15px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.6);
      .titleCard {
        @include font("Poppins-SemiBold", 600, 30px, #497078);
        width: 100%;
        text-align: center;
        margin-bottom: 16px;
      }
      .containerCheck {
        @include flex(row, center, center);
        gap: 8px;
        margin-left: 32px;
        img {
          width: 40px;
        }
        .textCard {
          @include font("Poppins-SemiBold", 600, 20px, #343f56);
          font-style: italic;
        }
      }
      .butonStart {
        cursor: pointer;
        @include flex(row, center, center, 180px);
        position: absolute;
        @include font("Poppins-Medium", 600, 19px, #efefef);
        background-image: linear-gradient(to right, #6eaebb, #497078);
        height: 48px;
        box-sizing: border-box;
        bottom: -35px;
        right: calc(50% - 90px);
        border-radius: 15px;
        background-color: #6eaebb;
      }
    }
  }
}
