.bigSpinner,
.bigSpinner:after {
  border-radius: 50%;
  width: 6vw;
  height: 6vw;
}
.bigSpinner {
  margin: 4vw auto;
  position: relative;
  text-indent: -9999em;
  border-top: 1vw solid lightgray;
  border-right: 1vw solid lightgray;
  border-bottom: 1vw solid lightgray;
  border-left: 1vw solid #6eaebb;
  transform: translateZ(0);
  animation: spinner-animation 1.1s infinite linear;
}

@keyframes spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
