@import "../../../styles/functions.scss";

.containerProfile {
  @include flex(row, flex-start, flex-start, 100%);
  gap: 22px;
  .imageProfile {
    width: 102px;
    border-radius: 50%;
  }
  .profileText {
    @include flex(column, flex-start, flex-start);
    gap: 4px;
    .containerTitle {
      gap: 12px;
      @include flex(row, center, center);
      img {
        cursor: pointer;
        margin-top: 2px;
        width: 28px;
        @media (max-width: 768px) {
          width: 20px;
          margin-left: 8px;
         }
      }
      .title {
        @include font("Poppins-ExtraBold", 800, 40px, #497078);
        @media (max-width: 768px) {
          font-size: 30px;
         }
      }
    }
    .name {
      @include font("Poppins-SemiBold", 500, 30px, #497078);
      text-transform: uppercase;
    }
  }
}
