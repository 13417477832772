@mixin font($family, $weight, $size, $color) {
  font-family: $family, sans-serif;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}
@mixin flex($direction, $align, $justify, $width: none) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  width: $width;
}
