@import "../../../styles/functions.scss";

.container {
  @include flex(row, center, flex-start, 100%);
  gap: 0px 60px;
  padding: 0px 60px;
  height: 100vh;
  @media (max-width: 768px) {
    display: none;
  }
  .info {
    @include flex(column, flex-end, center);
    flex: 1;
    gap: 36px;

    .title {
      @include font("Poppins-SemiBold", 600, 30px, #497078);
      line-height: 45px;
      text-align: end;
      margin-bottom: 16px;
    }
    .subTitle {
      @include flex(column, flex-start, flex-start);
      @include font("Poppins-Medium", 500, 20px, #373737);
      gap: 32px;
      text-align: end;
      width: 450px;
    }
  }
  .imageContainer {
    flex: 1;
    position: relative;
    .imageHome {
      width: 100%;
      border-radius: 57px;
    }
    .textImage {
      @include font("Poppins-SemiBold", 600, 17px, #497078);
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 400px;
      gap: 20px;
      padding: 24px;
      bottom: -80px;
      right: -20px;
      line-height: 110%;
      font-style: italic;
      border: #9ec5ce 2px solid;
      border-radius: 15px;
      box-sizing: border-box;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}
