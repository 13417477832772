@import "../../../../styles/functions.scss";

.containerInput {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .inputAI {
    cursor: pointer;
    @include font("Poppins-Medium", 600, 14px, #4c4f63);
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 46px;
    padding: 12px 50px 12px 10px;
    border: none;
    background-color: #88bcc6;
    outline: none;
    line-height: 20px;
    overflow: hidden;
  }

  .imgAI {
    position: absolute;
    width: 20px;
    height: 25px;
    bottom: 12px;
    right: 8px;
  }
}
