@import "../../../../styles/functions.scss";

.container {
  @include flex(column, flex-start, flex-start, 100%);
  height: calc(100vh - 72px);
  gap: 50px;
  padding: 50px 60px 0px 60px;

  .containerHeader {
    .containerTitle {
      gap: 8px;
      @include flex(row, center, center);
      img {
        cursor: pointer;
        margin-top: 2px;
        width: 28px;
      }
      .title {
        @include font("Poppins-ExtraBold", 800, 40px, #497078);
      }
    }
    .boxHeader {
      @include font("Poppins-SemiBold", 600, 30px, #6eaebb);
    }
  }

  .textInfo {
    @include font("Poppins-Medium", 500, 15px, #373737);
    width: 80%;
    font-style: italic;
    line-height: 30px;
  }

  .containerButton {
    @include flex(row, center, center);
    gap: 70px;
    width: 100%;
    .buttomQuestion {
      cursor: pointer;
      @include flex(row, center, center, 182px);
      @include font("Poppins-Medium", 500, 20px, #6eaebb);
      border: #9ec5ce 2px solid;
      height: 50px;
      border-radius: 15px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3),
        0 4px 8px 3px rgba(60, 64, 67, 0.15);
    }
    .buttomQuestion:hover {
      box-shadow: 0 4px 6px 0 rgba(60, 64, 67, 0.3),
        0 6px 10px 4px rgba(60, 64, 67, 0.15);
    }
  }
}
