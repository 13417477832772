@import "../../../../styles/functions.scss";
.container {
  @include flex(column, flex-start, flex-start, 100%);
  background-color: #efefef;
  padding: 50px 50px 0px 50px;
  box-sizing: border-box;
  gap: 8px;
  @media (max-width: 768px) {
    padding: 10px 10px 0px 10px;
    justify-content: space-between;
    min-height: 100%;
    gap: 24px;
   }
  .content {
    @include flex(row, center, space-between, 100%);
    gap: 40px;
    @media (max-width: 768px) {
     flex-direction: column;
     gap: 24px;
    }
    .contentText {
      flex: 1;
      @include flex(row, center, center, 100%);
      .text {
        @include font("Poppins-Medium", 600, 15px, #373737);
        line-height: 30px;
        width: 100%;
        max-width: 430px;
        @media (max-width: 768px) {
          @include font("Poppins-Medium", 600, 15px, #373737);
          text-align: center;
          line-height: 20px;
          width: 90%;
        }
      }
    }
    .contentBox {
      flex: 1;
      .card {
        @include flex(column, flex-start, flex-start, 80%);
        min-width: 455px;
        position: relative;
        border-radius: 15px;
        bottom: 55px;
        padding: 24px;
        gap: 8px;
        border: #9ec5ce 3px solid;
        border-radius: 15px;
        box-shadow: 0 3px 3px 0 rgba(60, 64, 67, 0.3),
          0 2px 4px 3px rgba(60, 64, 67, 0.15);
          @media (max-width: 768px) {
            bottom: 0px;
            border: none;
            border-radius: 0px;
            box-shadow: none;
            padding: 0px;
            min-width: auto;
            width: 240px;
           }

        .titleCard {
          @include font("Poppins-SemiBold", 600, 30px, #497078);
          margin-bottom: 16px;
       
          @media (max-width: 768px) {
            display: none;
           }
        }
        .containerCheck {
          @include flex(row, center, center);
          img {
            width: 40px;
          }
          .textCard {
            @include font("Poppins-SemiBold", 600, 20px, #343f56);
            font-style: italic;
            @media (max-width: 768px) {
              font-size: 15px;
              }
          }
        }
        .butonStart {
           @media (max-width: 768px) {
            display: none;
           }
          position: absolute;
          top: -90px;
          right: -30px;
        }
      }
    }
  }

  .containerFooter {
    @include flex(column, center, center, 100%);
    gap: 16px;
    img {
      width: 152px;
    }
    .textFooter {
      @include font("Poppins-Medium", 600, 15px, #818181);
      font-style: italic;
      text-align: center;
      
    }
    .buttonFooter {
      margin-bottom: 16px;
      cursor: pointer;
      @include flex(row, center, center, 114px);
      @include font("Poppins-Regular", 600, 15px, #efefef);
      background-image: linear-gradient(to right, #6eaebb, #497078);
      height: 47px;
      box-sizing: border-box;
      border-radius: 15px;
      background-color: linear-gradient(
        122deg,
        rgba(255, 255, 255, 0.18) 17.03%,
        rgba(255, 255, 255, 0.05) 81.81%
      );
    }
    .buttonFooter:hover {
      transform: scale(1.01);
      box-shadow: 0 1px 1px 0 rgba(60, 64, 67, 0.3),
        0 2px 4px 1px rgba(60, 64, 67, 0.15);
    }
  }
}
