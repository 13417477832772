@import "../../styles/functions.scss";
.container {
  @include flex(column, flex-start, flex-start, 100%);
  gap: 20px;
  background-color: #efefef;
  min-height: 100vh;
  padding: 50px;
  box-sizing: border-box;

  .boxTerms {
    @include flex(row, center, center);
    @include font("Poppins-SemiBold", 600, 38px, #497078);
    border: rgba(73, 112, 120, 0.87) 3px solid;
    padding: 8px 16px;
    border-radius: 15px;
    box-shadow: 0 2px 6px 0 rgba(60, 64, 67, 0.3),
      0 6px 10px 4px rgba(60, 64, 67, 0.15);
  }

  .description {
    padding: 20px;
  }
}
